define(function (require) {
  const $ = require("jquery");
  const config = require("common/page-config");
  const FOCUSED_CLASS = "on";

  function getInputs (form) {
    return $(form).find("input[type=text], input[type=password], input[type=tel], input[type=email], textarea, select");
  }

  function focusFirstInput (form) {
    getInputs(form).filter(":first").focus();
  }

  function focusFirstErrorInput (form, fields) {
    if (!fields || !form) {
      return;
    }

    const MIN_DISTANCE_TO_BOTTOM = 300;
    const fieldsWithError = $(`#${fields.join(", #")}`, form);
    const firstErrorInput = fieldsWithError.first();

    if (config.isMobile) {
      // Ensure input field is visible, and not obscured by a virtual keyboard.
      firstErrorInput.one("focus", () => {
        // Check if distance between bottom of input and bottom of form
        // is bigger than the keyboard's height
        const formBottom = form.offset().top + form.height();
        const distance = window.innerHeight - formBottom;

        if (distance > 0 && distance < MIN_DISTANCE_TO_BOTTOM) {
          // Add container to the bottom of form with the missing height
          const extraHeightNode = $("<div/>")
            .addClass("extra-form-height")
            .css("height", `${distance}px`);

          form.append(extraHeightNode);

          // Add listener to remove added area on input field blur
          firstErrorInput.one("blur", () => {
            extraHeightNode.remove();
          });
        }

        const newScrollPosition = firstErrorInput.parent().offset().top - 150;

        // The distance is somewhat arbitrary. It is a compromise between ensuring
        // visibility, and leaving some content above the input for context.
        window.scrollTo(0, newScrollPosition);
      });
      firstErrorInput.focus();
    }
  }

  function focusFirstEmptyInput (form) {
    var $firstEmptyInput;

    getInputs(form).each(function (index, input) {
      var $input = $(input);

      if (!$input.val()) {
        $firstEmptyInput = $input;
        return false;
      }
      return true;
    });

    if ($firstEmptyInput) {
      $firstEmptyInput.focus();
    } else {
      focusFirstInput(form);
    }
  }

  function addClassWhenFocused ($form) {
    var $inputs = getInputs($form).not("select");

    $inputs.on("focus", function () {
      $(this).parents(".form-row").addClass(FOCUSED_CLASS);
    });

    $inputs.on("blur", function () {
      $(this).parents(".form-row").removeClass(FOCUSED_CLASS);
    });

    $form.on("change", ".radio label", function () {
      const $label = $(this);
      const $input = $label.find("input");

      $input.prop("checked", true);

      $label.parents(".choices").find(".radio label").removeClass("checked");
      $label.addClass("checked");
    });

    $form.on("change", ".checkbox label:not(.disabled)", function () {
      const field = $(this);
      const isChecked = field.hasClass("checked");

      field.toggleClass("checked", !isChecked);
    });
  }

  return {
    addClassWhenFocused: addClassWhenFocused,
    focusFirstInput: focusFirstInput,
    focusFirstEmptyInput: focusFirstEmptyInput,
    focusFirstErrorInput: focusFirstErrorInput
  };
});
