/*eslint-disable */
define(['jquery', 'modernizr'], function($) {
    var SCROLLABLE_CONTAINER_CLASS = '.b-scroll-can-be-disabled';
    var DATA_NAME = 'scroll-disable';

    var $body = $('body');

    function disable($scrollableElement) {
        var $scrollableContainer = getScrollableContainer($scrollableElement);
        if ($scrollableContainer.length == 0) {
            return;
        }
        var disableState = $scrollableContainer.data(DATA_NAME);

        if (disableState){
            disableState.count++;
        } else {
            disableState = {
                overflow:	$scrollableContainer.css("overflow"),
                count:				1
            }

            $scrollableContainer.css("overflow", "hidden");
            $scrollableContainer.width('100%');
        };

        $scrollableContainer.data('scroll-disable', disableState);

        if (isTouchAndBodyContainer($scrollableContainer)) {
            $body.on('touchmove', touchMove);
        }
    }

    function enable($scrollableElement) {
        var $scrollableContainer = getScrollableContainer($scrollableElement);
        if ($scrollableContainer.length == 0) {
            return;
        }
        var disableState = $scrollableContainer.data(DATA_NAME);

        if (!disableState) {return;};

        disableState.count--;

        if (disableState.count === 0) {
            $scrollableContainer.css("overflow", disableState.overflow || "visible");
            $scrollableContainer.width("auto");
            $scrollableContainer.data('scroll-disable', null);
        } else {
            $scrollableContainer.data('scroll-disable', disableState);
        }

        if (isTouchAndBodyContainer($scrollableContainer)) {
            $body.off('touchmove', touchMove);
        }
    }

    function getScrollableContainer($element) {
        return $element.parents(SCROLLABLE_CONTAINER_CLASS).first();
    }

    function isTouchAndBodyContainer($container) {
        return $container[0].tagName.toLowerCase() === 'body' && Modernizr.touch
    }

    function touchMove(event) {
        if ($(event.target).hasClass('ui-widget-overlay')) {
            event.preventDefault();
        }
    }

    function disableContainerScrollOnHover($scrollableElement) {
        $scrollableElement.on('mouseenter', function() {
            if (isScrollable($scrollableElement)) {
                disable($scrollableElement);
            }
        });

        $scrollableElement.on('mouseleave', function() {
            if (isScrollable($scrollableElement)) {
                enable($scrollableElement);
            }
        });
    }

    function isScrollable($element) {
        return $element[0].scrollHeight -1 > $element[0].clientHeight;
    }

    function enableContainerScrollOnHover($scrollableElement) {
        $scrollableElement.off('mouseenter');
        $scrollableElement.off('mouseleave');

        enable($scrollableElement);
    }

    return {
        disable: disable,
        enable: enable,
        disableContainerScrollOnHover: disableContainerScrollOnHover,
        enableContainerScrollOnHover: enableContainerScrollOnHover
    }
});
