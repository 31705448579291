/*eslint-disable */
define(function (require) {

    var $                  = require('jquery');
    var _                  = require('underscore');
    var containerScrolling = require('common/util/container-scrolling');
    var events             = require('common/event-aggregator');
    var log                = require('common/log');

    var openPopups = [];

    /**
     * Create a Wahanda style popup.
     *
     * Any elements with a class of "a-close" will close the popup when clicked.
     *
     * @param selector           a string with a selector for finding the popup's root element.
     * @param optionsOverrides   an object whose properties are jQueryUI dialog options.
     *                           The properties will supplement of override the default options for a Wahanda popup.
     *
     *                           Wahanda popup specific options:
     *                             notTitlebar         If truthy, the title bar will be hidden.
     *                             hideCloseButton     If truthy, the close (round X) button will be hidden.
     *                             ignoreOverlayClick  If truthy, the popup cannot be dismissed by clicking on the overlay.
     *                             popupClass          Additional classes to be added besides on the popup, besides the default ones.
     *
     * NOTE: There is an issue with jQuery UI Popup, when opening a popup on top of another popup.
     *       Clicking the overlay and then pressing the ESC key closes the popup underneath the active one.
     *
     * LINK: http://jsfiddle.net/n725A/1/
     *
     */
    window.Popup = function(selector, optionsOverrides) {
        if (!$.fn.dialog) {
            log.client(log.INFO, 'jquery ui dialog not available', selector);
            return;
        }

        var self = this;
        var options;

        this.dialogElement = $(selector);

        optionsOverrides = optionsOverrides || {};

        if (this.dialogElement.data("title")) {
            optionsOverrides.title = this.dialogElement.data("title");
        }

        options = {
            autoOpen: false,
            modal:true,
            draggable: false,
            dialogClass: 'wahanda-popup',
            resizable: false,
            width: this.dialogElement.outerWidth(),
            open: function(event, ui) {
                openPopups.push(self);

                containerScrolling.disable(self.dialogElement);

                if (optionsOverrides.hideCloseButton) {
                    $(this).parent().find('.ui-dialog-titlebar-close').hide();
                }

                if (optionsOverrides.noTitlebar) {
                    $(this).parent().find(".ui-dialog-titlebar").hide()
                }

                if (optionsOverrides._open) {
                    optionsOverrides._open.call(this);
                }

                $(window).trigger('resize');
            },
            beforeClose: function(event, ui) {
                // Restore any overriden overlay styles.
                if (self.oldOverlayBackgroundColor) {
                    $('.ui-widget-overlay').css('background-image', '');
                    $('.ui-widget-overlay').css('background-color', self.oldOverlayBackgroundColor);
                }
                if (self.oldOverlayOpacity) {
                    $('.ui-widget-overlay').css('opacity', self.oldOverlayOpacity);
                }

                self.$overlay.removeClass('b-claimed');

                containerScrolling.enable(self.dialogElement);

                openPopups.pop();
                if (openPopups.length) {
                    // Focus the previously opened popup (in the case of popups on top of popups).
                    // This is necessary for the closeOnEscape option to work correctly.
                    var previousPopup = openPopups[openPopups.length - 1];
                    previousPopup.dialogElement.parents('.ui-dialog').focus();
                }

                if (self.cancel) {
                    self.cancel();
                }

                // emit close event
                events.trigger(events.POPUP_CLOSED, self.getOpenPopupsCounter());
            }
        };

        if (optionsOverrides.useElementDimensions) {
            options.height = this.dialogElement.height();
        }

        if (optionsOverrides.open) {
            optionsOverrides._open = optionsOverrides.open;
            delete optionsOverrides.open;
        }

        if (optionsOverrides.popupClass) {
            options.dialogClass += ' ' + optionsOverrides.popupClass;
            delete optionsOverrides.popupClass;
        }

        // Merge overrides in to options.
        _.extend(options, optionsOverrides);

        this.dialog = this.dialogElement.dialog(options);
        this.optionsOverrides = optionsOverrides;

        this.dialogElement.find(".a-close").on("click", _.bind(self.close, self));
    };

    Popup.closeAll = function() {
        _.each(openPopups.reverse(), function(popup) {
            popup.close();
        });
    }

    Popup.prototype.open = function (callback) {
        var self = this;

        this.dialog.dialog('open');
        if (this.dialogElement.find(".popup-content").length > 0) {
            this.dialogElement.find(".popup-content")[0].scrollTop = 0;
        }
        // Dismiss the dialog if the modal dialog's translucent overlay is clicked.
        // Although not exactly a documented approach, this is unlikely to break, even in future
        // versions of jQuery UI.
        // Be careful to only listen for click on the freshly opened popup's overlay (when popups are
        // opened on top of popups). Use the (terribly named) b-claimed class for this.
        this.$overlay = $('.ui-widget-overlay:not(.b-claimed)');
        this.$overlay.addClass('b-claimed');

        if (this.optionsOverrides['z-index']) {
            this.dialogElement.parent().css({
                'z-index': this.optionsOverrides['z-index']
            });

            this.$overlay.css({
                'z-index': this.optionsOverrides['z-index'] - 1
            });
        }

        if (!this.optionsOverrides.ignoreOverlayClick) {
            this.$overlay.click(function() {
                self.dialog.dialog('close');
            });
        }

        // position the dialog to the center of the screen
        self.position();

        // Optionally use an image for the overlay (instead of a tranlucent overlay).
        if (this.optionsOverrides.overlayBackgroundImage) {
            // Preserve overlay styles.
            this.oldOverlayBackgroundColor = $('.ui-widget-overlay').css('background-color');
            this.oldOverlayOpacity = $('.ui-widget-overlay').css('opacity');

            // Use the supplied image for the overlay.
            $('.ui-widget-overlay').css('background-color', '');
            $('.ui-widget-overlay').css('background-image', 'url(' + this.optionsOverrides.overlayBackgroundImage + ')');
            $('.ui-widget-overlay').css('opacity', '1');
        }

        if (typeof callback == "function") {
            callback();
        }
    };

    Popup.prototype.position = function () {
        var containerWidth = Math.max($(document).width(), document.body.scrollWidth);
        var dialogNode = this.dialogElement.parent();
        var positionLeft = (containerWidth - dialogNode.width()) / 2;
        dialogNode.css('left', positionLeft);
    },

    Popup.prototype.close = function() {
        this.dialog.dialog('close');
    };

    Popup.prototype.getOpenPopupsCounter = function () {
        return openPopups.length;
    };

    Popup.showMessage = function(message, title) {

        var $message = $("<span></span");
        $message.addClass("message");
        $message.addClass("error");

        var $div = $("<div></div>");
        $div.attr("id", "idtest");
        $div.addClass("hide");
        $div.data("title", title);
        $div.append($message);

        $("body").append($div);

        var popupContent = $("#idtest");
        popupContent.find(".message").text(message);

        var popup = new Popup(popupContent, {
            modal : true,
            width : 300,
            dialogClass : "wahanda-popup popup-dialog"
        });

        popup.open();
    };

    /**
     * This function will open the popup with cancel and confirmation buttons
     * @param  {Object} options
     * @return void
     */
    Popup.prototype.showConfirmation = function (options) {
        // TODO: Get content from a template file
        var popup,
            self = this,
            $message = $('<p/>').addClass('message confirmation').text(options.message),
            $actions = $('<div/>').addClass('actions'),
            $content = $('<div/>').addClass('confirm-popup').append($message, $actions);

        // attach events to run cancel and confirm callbacks
        if (options.cancel) {
            // append button to action area
            $cancelAction = $('<span/>').addClass('button other-button cancel-button').append($('<span/>').addClass('button-value').text(options.cancel.label || 'Cancel'));
            $actions.append($cancelAction);

            if (options.cancel.callback) {
                this.cancel = options.cancel.callback;
                $content.find('.cancel-button').one('click', _.bind(function () {
                    this.cancel = null;
                    options.cancel.callback();
                    this.close();
                }, this));
            }
        }

        if (options.confirm) {
            // append button to action area
            $confirmAction = $('<span/>').addClass('button main-button search').append($('<span/>').addClass('button-value').text(options.confirm.label || 'Continue')),
            $actions.append($confirmAction);

            if (options.confirm.callback) {
                $content.find('.main-button').one('click', _.bind(function () {
                    this.cancel = null;
                    options.confirm.callback();
                }, this));
            }
        }

        // set dialog content
        this.dialogElement.html($content);
        this.open();
    };

    return Popup;
});
